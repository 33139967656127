<template>
    <div class="body">
        <header class="">
            <div class="container flex justify-between px-4 py-4 mx-auto">
                <a href="/">
                    <img class="w-auto h-12" src="../assets/logotype-vasttrafik.svg">
                </a>

                <div class="menu-options">
                </div>
            </div>

        </header>

        <div class="container px-4 mx-auto">
            <h1 class="mt-12 mb-12 text-3xl lg:text-6xl">Terminalkartor</h1>
            <div class="w-3/4 mx-auto text-left">
                <p class="mb-4">
                    Sidan administreras av extern partner.<br>
                    Är det fel på länkarna eller att sidan inte fungerar så anmäl detta då till: <a href="mailto:ITS@vasttrafik.se">ITS@vasttrafik.se</a>
                </p>
                <p class="mb-4">
                    På hemsidan kan du söka efter terminalkartor med hjälp av fritext.
                    När du klickar på länken kommer terminalkartan öppnas (PDF). De terminalkartor som finns på hemsidan, är också de kartor som finns uppsatta på terminalerna. Vissa terminaler saknar karta och visas inte på sidan.
                </p>
                <p class="mb-4">
                    Är det fel information på terminalkartorna kan göra en felanmälan till.
                    <a href="mailto:trafikdatagruppen@vasttrafik.se">trafikdatagruppen@vasttrafik.se</a> 
                </p>
            </div>

            <div class="mx-auto mt-24 mb-4">
                <div class="flex px-4 py-2 border-t border-b">
                    <div class="w-12 h-auto">
                        <icon class="py-2 pl-4 pr-2" :color="'text-gray-200'" :icon="'search'"></icon>
                    </div>
                    <input v-model="filter.text" type="text" class="w-full px-2 py-2 outline-none" placeholder="Filtrera på namn..." @keyup="doFilter">
                </div>
            </div>
            <table class="w-full mx-auto text-sm text-left table-auto lg:text-base">
                <thead>
                    <tr class="text-gray-200 border-b-2 border-gray-200 ">
                        <th class="px-1 py-2 lg:px-8">Namn</th>
                        <th class="px-1 py-2 lg:px-4">Hållplats nummer</th>
                        <th class="px-1 py-2 lg:px-4">Hållplats läge</th>
                        <th class="px-1 py-2 lg:px-4">Hämta</th>
                    </tr>
                </thead>
                <tbody>
                    <tr 
                        v-for="(map, index) in maps" 
                        :key="map.name + index"
                        class="px-8 border-b">
                        <td class="px-1 py-4 lg:px-8"> {{ map.name }} </td>
                        <td class="px-1 py-4 lg:px-4"> {{ map.stop_area }} </td>
                        <td class="px-4 py-4"> {{ map.site }} </td>
                        <td class="px-4 py-4"><a onclick="window.fathom.trackEvent('download');" :href="map.url" download><span class="hidden lg:inline-block">Ladda ned</span> <icon class="inline-block w-4 h-4 lg:ml-2" :color="'text-gray-200'" :icon="'download'"></icon></a></td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import Icon from '../components/icon.vue'


export default {
    name: 'Index',
    props: {
        msg: String
    },

    components: {
        Icon,
    },

    data() {
        return {
            maps: [],
            maps_base: [],
            filter: {
                text: '',
            },
        }
    },

    created() {
        this.fetchData();
    },


    methods: {
        async fetchData() {
            let url = 'https://katalog.infab.io/api/terminalmaps';

            var data = await axios.get(url);
            this.maps = data.data.data;
            this.maps_base = data.data.data;
        },
        doFilter() {
            var filteredArray =  this.maps_base.filter(external => external.name.toLowerCase().indexOf(this.filter.text.toLowerCase()) > -1)
            this.maps = filteredArray;
        }
    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    

    header {
        background-color: #00394d;
        
    }

    .body {
        font-family: Founders;
        font-weight: normal;
    }

    h1, h2, h3 {
        font-family: "Founders";
        font-weight: bold
    }

    h3 {
        margin: 40px 0 0;
    }
    ul {
        list-style-type: none;
        padding: 0;
    }
    li {
        display: inline-block;
        margin: 0 10px;
    }
    a {
        color: #007EB1;
    }
</style>
